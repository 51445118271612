import React, { useState, useRef } from 'react'
import { Box, HStack, Spacer, Button, Text } from '@chakra-ui/react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../export-panel/export/export.scss'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_WORKHOURS,
//   sectionDetailedTrack,
// } from '../repository/repo.utils'

function AddWorkTime({ addBusinessHours }) {
	const [startTime, setStartTime] = useState()
	const [endTime, setEndTime] = useState()
	const [errorMsg, setErrorMsg] = useState()

	const onSaveWorkingHours = e => {
		let businessHours = {}
		let isValidHours = true
		if (
			!startTime ||
			!endTime ||
			startTime.setMilliseconds(0) >= endTime.setMilliseconds(0)
		) {
			setErrorMsg('Start Time should be less than End time')
			isValidHours = false
		}
		if (isValidHours) {
			setErrorMsg('')
			businessHours = {
				business_hour_start_time: moment(startTime).format('LT'),
				business_hour_end_time: moment(endTime).format('LT'),
			}
			addBusinessHours(businessHours)
			setStartTime()
			setEndTime()
		}
	}
	return (
		<Box p={2} bg='white' borderRadius='sm' boxShadow='sm' w='full'>
			<HStack justifyContent='space-between'>
				<HStack>
					<DatePicker
						selected={startTime}
						className='date-css'
						onChange={date => setStartTime(date)}
						showTimeSelect
						showTimeSelectOnly
						timeIntervals={30}
						timeCaption='Time'
						dateFormat='h:mm a'
						placeholderText='Start Time'
					/>
					<Spacer />
					<DatePicker
						selected={endTime}
						showTimeSelectOnly={true}
						showTimeSelect
						dateFormat='hh:mm a'
						className='date-css'
						value={endTime}
						timeCaption='Time'
						onChange={date => {
							setEndTime(date)
						}}
						placeholderText='End Time'
					/>
					<Spacer />
					{errorMsg !== '' && (
						<Text color='red.400' p={2}>
							{errorMsg}
						</Text>
					)}
				</HStack>

				<Spacer />

				<HStack spacing={2}>
					<Button
						variant='solid'
						size='sm'
						colorScheme={localStorage.getItem('color')}
						bg={localStorage.getItem('color')}
						type='submit'
						borderRadius='sm'
						minW='100px'
						onClick={e => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_WORKHOURS,
							//   action: 'Work Time',
							//   label: 'Update Working Hours',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Workhours',
							//   buttonName: 'Update Working Hours',
							// })
							onSaveWorkingHours(e)
						}}
					>
						Update Working Hours
					</Button>
				</HStack>
			</HStack>
		</Box>
	)
}

export default AddWorkTime
