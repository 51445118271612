import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useLanguageContext } from '../../../context/locale.context'
import { MdFileDownload } from 'react-icons/md'
import { sectionDetailedTrack } from '../../repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../../repository/repo.utils'

export const DocTable = ({ dataList, isLoading, downloadAwsFile }) => {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const {
    state: { locale },
  } = useLanguageContext()

  const bottomRef = useRef()
  useEffect(() => {
    setTableData(dataList)
    if (!!dataList.length > 30) {
      scrollToBottom()
    }
  }, [dataList])

  const scrollToBottom = () => {
    if (!!bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const renderTableBody = () => {
    let tablerows =
      tableData.length > 0 ? (
        tableData.map((row, index) => {
          return (
            <Tr
              bgColor="white"
              borderRadius="md"
              boxShadow="sm"
              key={index + 1 + '1A'}
            >
              <Td textAlign="center" p="2">
                {' '}
                {(currentPage - 1) * pageSize + index + 1}{' '}
              </Td>
              <Td textAlign="center" p="2">
                {row.product ? row.product : row.productid}
              </Td>
              <Td textAlign="center" p="2">
                {row.documenttitle}
              </Td>
              <Td textAlign="center" p="2">
                {row.documentdesc}
              </Td>

              <Td textAlign="center" p="2">
                <IconButton
                  borderRadius="md"
                  bg="white"
                  zIndex={1}
                  boxShadow="md"
                  size="sm"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_VIDM,
                      action: 'Document Table',
                      label: 'Download AWS File',
                    })
                    downloadAwsFile(row.s3url)
                  }}
                  icon={<MdFileDownload />}
                />
              </Td>
            </Tr>
          )
        })
      ) : (
        <Tr>
          <Td colSpan={9}>{locale['No Data is Exported']}</Td>
        </Tr>
      )
    if (!!tablerows && tablerows.length > 1) {
      tablerows.push(
        <tr>
          <div ref={bottomRef}></div>
        </tr>
      )
    }

    return tablerows
  }
  return (
    <Box h="calc(100vh - 224px)" w="full" overflowY="scroll">
      {isLoading ? (
        <HStack>
          {[1, 2, 3, 4, 5].map(() => (
            <Skeleton w={'30px'} />
          ))}
        </HStack>
      ) : (
        <Table
          style={{
            position: 'relative',
            borderCollapse: 'separate',
            borderSpacing: '0px 10px',
          }}
        >
          <Thead>
            <Tr m="2" boxShadow="sm" borderRadius="md">
              <Th textAlign="center" pos="sticky" top="0" bg="white">
                #
              </Th>
              <Th textAlign="center" pos="sticky" top="0" bg="white">
                {locale['Product Id']}
              </Th>
              <Th textAlign="center" pos="sticky" top="0" bg="white">
                {locale['Product Name']}
              </Th>
              <Th textAlign="center" pos="sticky" top="0" bg="white">
                {locale['Product Description']}
              </Th>
              <Th textAlign="center" pos="sticky" top="0" bg="white" zIndex={2}>
                {locale['Action']}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{renderTableBody()}</Tbody>
        </Table>
      )}
    </Box>
  )
}

export default DocTable
