import {
  Avatar, Badge, Box, Divider, Drawer,
  DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader,
  DrawerOverlay, Tag,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdDescription } from 'react-icons/md';
import * as utils from '../../utils/common.util';
import { VIDM_STATUS } from './vidm.constant';

export const CommentsTimeLine = ({ onOpen, onClose, document }) => {
  const [comments, setComments] = useState(
    JSON.parse(document.comment_data).commentdata
  );
  return (
    <>
      <Drawer isOpen={onOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader boxShadow="sm">
            <Box>
              {document.Status.toLowerCase() === VIDM_STATUS.APPROVED && (
                <Badge mx="5px" variant="outline" colorScheme={localStorage.getItem('color')}>
                  Approved
                </Badge>
              )}
              {document.Status.toLowerCase() === VIDM_STATUS.REJECTED && (
                <Badge mx="5px" variant="outline" colorScheme="red">
                  Rejected
                </Badge>
              )}
              {document.Status.toLowerCase() === VIDM_STATUS.REVISED && (
                <Badge mx="5px" variant="outline" colorScheme="blue">
                  {' '}
                  Revised
                </Badge>
              )}
              {document.Status.toLowerCase() === VIDM_STATUS.NOT_GENRETED && (
                <Badge mx="5px" variant="outline" colorScheme="black">
                  {' '}
                  Revised
                </Badge>
              )}

              {
                <Badge minW="50px" px="2" ml="10">
                  {document.InitiatingCircle}
                </Badge>
              }
              {
                <Badge minW="50px" px="2" ml="10">
                  {document.product}
                </Badge>
              }
              <VStack alignItems="flex-start" flex={1}>
                <Text
                  as="span"
                  flex={1}
                  fontSize="md"
                  color="black"
                  textTransform="capitalize"
                >
                  <Text as="span">Customer Name : </Text>{' '}
                  {document.CustomerName}
                </Text>
                <Text
                  as="span"
                  flex={1}
                  fontSize="md"
                  color="black"
                  textTransform="capitalize"
                >
                  <Text as="span"> Request No. : </Text>{' '}
                  {document.RequestNumber}
                </Text>
                <Text
                  as="span"
                  flex={1}
                  fontSize="md"
                  color="black"
                  textTransform="capitalize"
                >
                  <Text as="span"> Account Code : </Text>{' '}
                  {document.account_code}
                </Text>
                <Text
                  as="span"
                  flex={1}
                  fontSize="md"
                  color="black"
                  textTransform="capitalize"
                >
                  <Text as="span"> Employee Name : </Text>{' '}
                  {document.EmployeeName}
                </Text>
              </VStack>
            </Box>
          </DrawerHeader>
          <DrawerBody>
            {comments.length > 0 &&
              comments.map(comment => {
                return (
                  <Box
                    key={utils.getCurrentTimeDateISTTemp(comment.ActionDate)}
                    py="5"
                  >
                    <Avatar name={comment.ActionBy} />
                    <Text>{comment.ActionBy}</Text>
                    <Tag>
                      {utils.getCurrentTimeDateISTTemp(comment.ActionDate)}
                    </Tag>
                    <Box>
                      <MdDescription size="14" color="#000" />
                      {comment.Comments}
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default CommentsTimeLine;
