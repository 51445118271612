/* eslint-disable no-empty-pattern */
import React, { useState } from 'react'
import { VStack, Box, Center, Button } from '@chakra-ui/react'
import Header from '../vidmHeader'
import DocTable from '../document/docTable'
import { downloadAwsFile } from '../../../utils/aws.util'
import { useLanguageContext } from '../../../context/locale.context'
import { useGetSearchBCDocs } from '../../../hooks/vidm.hooks'
import { sectionDetailedTrack } from '../../repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../../repository/repo.utils'

export const Document = ({}) => {
  const { mutate, isLoading } = useGetSearchBCDocs()
  const {
    state: { locale },
  } = useLanguageContext()
  const SearchFilterList = [
    {
      label: locale['And'],
      key: 'and',
      value: 1,
    },
    {
      label: locale['Or'],
      key: 'or',
      value: 2,
    },
    {
      label: locale['Contains'],
      key: 'contains',
      value: 3,
    },
  ]
  const [selectedFilter, setSelectedFilter] = useState(SearchFilterList[0])
  const [tableData, setTableData] = useState([])
  const [query, setQuery] = useState('')
  const [isLoadMore, setIsLoadMore] = useState(false)

  const loadDocsSearched = (text, pageNo = 0) => {
    mutate(
      {
        page_start: pageNo,
        page_limit: 30,
        search_text: text ? text : query,
        search_option: selectedFilter.key,
      },
      {
        onSuccess: async res => {
          const {
            data: { status, response },
          } = res
          if (status === 200) {
            if (response.length > 0) {
              setIsLoadMore(true)
            } else {
              setIsLoadMore(false)
            }
            if (pageNo > 0) {
              setTableData([...tableData, ...response])
            } else {
              setTableData(response)
            }
          }
        },
        onError: async err => {
          console.log(err)
        },
      }
    )
  }
  const downloadAwsFileUrl = url => {
    downloadAwsFile(url)
  }
  return (
    <>
      <VStack spacing={2} w="full" h={'calc(100vh - 170px)'}>
        <Header
          selected={selectedFilter}
          onChange={setSelectedFilter}
          onSearch={value => {
            setQuery(value)
            loadDocsSearched(value)
          }}
          filterOptions={SearchFilterList}
        />
        <DocTable
          dataList={tableData ? tableData : []}
          isLoading={isLoading}
          downloadAwsFile={downloadAwsFileUrl}
        />

        {tableData && tableData.length >= 30 && isLoadMore && (
          <Box>
            <Center>
              <Button
                bg={localStorage.getItem('color')}
                color="white"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_VIDM,
                    action: 'Document',
                    label: 'Load More',
                  })
                  loadDocsSearched(tableData.length)
                }}
              >
                {locale['Load More']}
              </Button>
            </Center>
          </Box>
        )}
      </VStack>
    </>
  )
}

export default Document
