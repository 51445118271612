import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, Text
} from '@chakra-ui/react';
import React from 'react';
import FileView from './FileView';
export const FileExplorerModal = ({ onOpen, onClose, document }) => {
  return (
    <>
      <Modal isOpen={onOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="56rem">
          <ModalHeader bg={localStorage.getItem('color')}>
            <Heading borderRadius="md" as="h2" size="md" color="white">
              {document.CustomerName}
            </Heading>
            <Text as="span" fontSize="sm" color="whiteAlpha.800">
              Request Number :
              <Text as="span" fontWeight="500">
                {' '}
                {document.RequestNumber}{' '}
              </Text>
            </Text>
            <Text as="span" fontSize="sm" color="whiteAlpha.800">
              Account code :
              <Text as="span" fontWeight="500">
                {' '}
                {document.account_code}
              </Text>
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Accordion allowToggle>
              {document.folders &&
                document.folders.length &&
                document.folders.map((folder, index) => {
                  return (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            {folder.FolderName.split('+').join(' ')}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Box>
                          <FileView />
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};
export default FileExplorerModal;
