/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Text, Button } from '@chakra-ui/react'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../repository/repo.utils'
export const FileView = ({ path }) => {
  const [fileList, setFileList] = useState([])
  useEffect(() => {
    if (path) {
      let url = path.replace('https://', '')
      let paths = url.split('.s3.ap-south-1.amazonaws.com/')
      //TODO: get File list from s3 bucket
    }
  }, [])

  const downloadFile = (key, fileName) => {
    console.log(key, fileName)
    //TODO: code for download selected file
  }
  return (
    <>
      {fileList.length > 0 &&
        fileList.map((file, index) => {
          let keys = file.Key.split('/')
          let fileName = keys[keys.length - 1]
          return (
            <Box>
              <Text>{fileName}</Text>
              <Button
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_VIDM,
                    action: 'File View',
                    label: 'Download',
                  })
                  downloadFile(`${file.Key}`, fileName)
                }}
              >
                Download
              </Button>
            </Box>
          )
        })}
    </>
  )
}
export default FileView
