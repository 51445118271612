import {
  Box,
  Center,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useLanguageContext } from '../../context/locale.context'
import { MdFileDownload, MdGroupWork } from 'react-icons/md'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../repository/repo.utils'

const VidmTable = ({
  vidmList,
  isLoading,
  openFileExplorer,
  openCommentExplorer,
}) => {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const bottomRef = useRef()
  const {
    state: { locale },
  } = useLanguageContext()
  useEffect(() => {
    setTableData(vidmList)
    if (!!vidmList && vidmList.length >= 100) {
      scrollToBottom()
    }
  }, [vidmList])
  const scrollToBottom = () => {
    if (!!bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
  const renderTableBody = () => {
    let tablerows =
      tableData.length > 0 ? (
        tableData.map((row, index) => {
          return (
            <Tr
              bgColor="white"
              borderRadius="md"
              boxShadow="sm"
              key={Math.random() * Date.now() + '1A'}
            >
              <Td textAlign="center" p="2">
                {' '}
                {(currentPage - 1) * pageSize + index + 1}{' '}
              </Td>
              <Td textAlign="center" p="2">
                {row.RequestNumber}
              </Td>
              <Td textAlign="center" p="2">
                {row.account_code}
              </Td>
              <Td textAlign="center" p="2">
                {row.CustomerName}
              </Td>
              <Td textAlign="center" p="2">
                {row.RequestInitiationDate}
              </Td>
              <Td textAlign="center" p="2">
                {row.InitiatingCircle}
              </Td>
              <Td textAlign="center" p="2">
                {row.Segment}
              </Td>
              <Td textAlign="center" p="2">
                {row.product}
              </Td>
              <Td textAlign="center" p="2">
                {row.EmployeeName} ({row.Designation})
              </Td>
              <Td textAlign="center" p="2">
                {row.Status}
              </Td>
              <Td textAlign="center" p="2">
                <IconButton
                  borderRadius="md"
                  zIndex={1}
                  bg="white"
                  boxShadow="md"
                  size="sm"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_VIDM,
                      action: 'Vidm Table',
                      label: 'Open File Explorer',
                    })
                    openFileExplorer(row)
                  }}
                  icon={<MdFileDownload />}
                />
                <IconButton
                  borderRadius="md"
                  zIndex={1}
                  mx="2px"
                  bg="white"
                  boxShadow="md"
                  size="sm"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_VIDM,
                      action: 'Vidm Table',
                      label: 'Open Comment',
                    })
                    openCommentExplorer(row)
                  }}
                  icon={<MdGroupWork />}
                />
              </Td>
            </Tr>
          )
        })
      ) : (
        <Tr>
          <Td colSpan={9}>{locale['No Data is Exported']}</Td>
        </Tr>
      )
    if (!!tablerows && tablerows.length > 1) {
      tablerows.push(
        <tr>
          <div ref={bottomRef}></div>
        </tr>
      )
    }

    return tablerows
  }

  if (!!isLoading) {
    return (
      <Box w="full" h="calc(100vh - 180px)">
        {[1, 2, 3, 4, 5].map(item => {
          return (
            <Skeleton key={Math.random() * 3.3} minH="50px" w="full" my={3} />
          )
        })}
      </Box>
    )
  }

  return (
    <Box>
      {tableData.length > 0 ? (
        <>
          <Box h="calc(100vh - 224px)" overflowY="scroll">
            <Table
              style={{
                position: 'relative',
                borderCollapse: 'separate',
                borderSpacing: '0px 10px',
              }}
            >
              <Thead>
                <Tr m="2" boxShadow="sm" borderRadius="md">
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    #
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Request No.']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Account Code']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Customer Name']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Initiation Date']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Initiating Circle']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Segment']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Product']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Employee']}
                  </Th>
                  <Th textAlign="center" pos="sticky" top="0" bg="white">
                    {locale['Status']}
                  </Th>
                  <Th
                    textAlign="center"
                    pos="sticky"
                    top="0"
                    bg="white"
                    zIndex={2}
                  >
                    {locale['Action']}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>{renderTableBody()}</Tbody>
            </Table>
          </Box>
        </>
      ) : (
        <Center>
          <Text mt="20px">{locale['No Data is Exported']}</Text>
        </Center>
      )}
    </Box>
  )
}

export default VidmTable
