import React from 'react'
import {
	Box,
	CloseButton,
	Text,
	VStack,
	HStack,
	Spacer,
	Spinner,
	Center,
} from '@chakra-ui/react'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_WORKHOURS,
//   sectionDetailedTrack,
// } from '../repository/repo.utils'

function WorkhoursList({
	removeList,
	listType,
	listData,
	headerName,
	editMode,
	loading,
}) {
	const getFormattedData = () => {
		if (loading)
			return (
				<Center>
					<Spinner color='brand.900' />
				</Center>
			)
		if (listType === 'time') {
			return listData.length ? (
				listData.map(days => (
					<Box
						variant='solid'
						borderRadius='sm'
						fontWeight='400'
						boxShadow='md'
						p={2}
						key={Math.random() * Date.now()}
					>
						<Text as='span'>
							{days.business_hour_start_time} - {days.business_hour_end_time}
						</Text>
						{editMode ? (
							<CloseButton
								style={{ display: 'inline' }}
								ml={2}
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_WORKHOURS,
									//   action: 'Work Hours List',
									//   label: 'Remove Working Hours',
									// })
									// dataLayerTagManager('button_click', {
									//   viewName: 'Workhours',
									//   buttonName: 'Remove Working Hours',
									// })
									removeList(days, listType)
								}}
							/>
						) : null}
					</Box>
				))
			) : (
				<Text as='span'>No Records Found</Text>
			)
		} else if (listType === 'day') {
			return listData.length ? (
				listData.map(days => (
					<Box
						variant='solid'
						borderRadius='sm'
						fontWeight='400'
						boxShadow='md'
						p={2}
						key={days.value}
					>
						<Text as='span' ml={2}>
							{days.label}
						</Text>
					</Box>
				))
			) : (
				<Text as='span'>No Records Found</Text>
			)
		}
	}

	return (
		<>
			<VStack spacing='4' alignItems='flex-start' w={'100%'}>
				<HStack w='100%'>
					<Text color={localStorage.getItem('color')} fontWeight={600}>
						{headerName}
					</Text>
					<Spacer />
				</HStack>
				<HStack w='100%'>{getFormattedData()}</HStack>
			</VStack>
		</>
	)
}

export default WorkhoursList
