import React, { useState } from 'react'
import Select from 'react-select'
import { Box, HStack, Button } from '@chakra-ui/react'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_WORKHOURS,
//   sectionDetailedTrack,
// } from '../repository/repo.utils'

const options = [
	{ value: 'MONDAY', label: 'MONDAY' },
	{ value: 'TUESDAY', label: 'TUESDAY' },
	{ value: 'WEDNESDAY', label: 'WEDNESDAY' },
	{ value: 'THURSDAY', label: 'THURSDAY' },
	{ value: 'FRIDAY', label: 'FRIDAY' },
	{ value: 'SATURDAY', label: 'SATURDAY' },
	{ value: 'SUNDAY', label: 'SUNDAY' },
]
function AddWorkDays({ businessDays, alterBusinessDays }) {
	const [workDays, setWorkDays] = useState(() => businessDays)
	return (
		<Box p={2} bg='white' borderRadius='sm' boxShadow='sm' w='full'>
			<HStack>
				<HStack w='100%'>
					<div style={{ width: '50vw' }}>
						<Select
							value={workDays}
							onChange={days => {
								setWorkDays(days)
							}}
							isMulti
							options={options}
							closeMenuOnSelect={false}
							placeholder='Select work days of a week.'
						/>
					</div>
				</HStack>

				<HStack spacing={2}>
					<Button
						variant='solid'
						size='sm'
						colorScheme={localStorage.getItem('color')}
						bg={localStorage.getItem('color')}
						type='submit'
						borderRadius='sm'
						minW='100px'
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_WORKHOURS,
							//   action: 'Work Days',
							//   label: 'Update Work Days',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Workhours',
							//   buttonName: 'Update Work Days',
							// })
							alterBusinessDays(workDays)
						}}
					>
						Update Work Days
					</Button>
				</HStack>
			</HStack>
		</Box>
	)
}

export default AddWorkDays
