import {
	Flex,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	Box,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdSearch, MdSort } from 'react-icons/md'
import CustomMenu from '../../components/menu'
import { useLanguageContext } from '../../context/locale.context'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_VIDM } from './../repository/repo.utils'

export default function VidmHeader({
	filterOptions,
	selected,
	onChange,
	onSearch,
}) {
	const [searchQuery, setSearchQuery] = useState('')
	const [isError, setError] = useState(false)
	const [errMsg, setErrMsg] = useState('')
	const {
		state: { locale },
	} = useLanguageContext()

	const doValidate = text => {
		if (/^[a-zA-Z0-9 ]*$/.test(text)) {
			setError(false)
			setErrMsg('')
		} else {
			setError(true)
			setErrMsg(
				'Invalid input only alphanumeric with no special characters allowed'
			)
		}
	}
	return (
		<>
			<Flex justifyContent='flex-end' alignItems='center' w='full'>
				<InputGroup flex={1} boxShadow='md' maxW='300px' mx={2}>
					<Input
						bg='white'
						placeholder={locale['Smart search']}
						borderRadius='md'
						value={searchQuery}
						variant='filled'
						onChange={e => {
							doValidate(e.target.value)
							setSearchQuery(e.target.value)
						}}
						onKeyPress={event => {
							if (event.key === 'Enter' && !isError) {
								onSearch(event.target.value)
							}
						}}
						_focus={{
							border: 'none',
							background: 'white',
						}}
					/>
					<InputRightElement
						onClick={
							!isError
								? () => {
										sectionDetailedTrack({
											category: GA_CATEGORY_VIDM,
											action: 'Vidm Header',
											label: 'Search',
										})
										onSearch(searchQuery)
								  }
								: null
						}
						children={
							<Icon
								as={MdSearch}
								color={localStorage.getItem('color')}
								w={6}
								h={6}
							/>
						}
					/>
				</InputGroup>

				<CustomMenu
					optionList={filterOptions}
					selected={selected}
					isFilter={true}
					onSelect={item => {
						onChange(item)
					}}
					icon={MdSort}
				/>
			</Flex>
			<Box width='100%' textAlign='right'>
				{isError ? (
					<Text fontSize='13px' color={'red'}>
						{errMsg}
					</Text>
				) : (
					''
				)}
			</Box>
		</>
	)
}
